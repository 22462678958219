import FF from "../../components/forms/FormField.module.css";
import P from "./PricesView.module.css";

import type { LocsPricing } from "@warrenio/api-spec/spec.oats.gen";
import type { SelectedOs } from "../../modules/compute/os/os.ts";
import { useSizeSelect } from "../../modules/compute/useSizeSelect.ts";
import { VmSizeSelectCustom } from "../../modules/compute/VmSizeSelect.tsx";
import { EMPTY_PRICES, locListToObjectPrices } from "../../modules/pricing/resourcePricing.ts";
import { cn } from "../../utils/classNames.ts";

type DummyOs = "ubuntu" | "windows";

function makeDummyOs(os: DummyOs) {
    return {
        os_name: os,
        os_version: "1",
    } satisfies SelectedOs;
}

export function PackagePrices({ priceList, location }: { priceList: LocsPricing | undefined; location: string }) {
    //#region Hooks

    const { _os, props, setOs } = useSizeSelect(undefined, makeDummyOs("ubuntu"));
    const { os_name } = _os;
    //#endregion

    function osChange(value: DummyOs) {
        setOs(makeDummyOs(value));
    }

    return (
        <div className="p-2 lightBlock mb-2">
            <div className="pb-2">Package prices</div>

            <div className="flex items-start gap-0.5rem">
                <div className={P.Os}>
                    <div className={cn(P.Radio, os_name === "ubuntu" && P.isActive)} onClick={() => osChange("ubuntu")}>
                        Other
                    </div>
                    <div
                        className={cn(P.Radio, os_name === "windows" && P.isActive)}
                        onClick={() => osChange("windows")}
                    >
                        Windows
                    </div>
                </div>

                <div className={cn(P.Radios, FF.FormFieldRadioGroup)}>
                    <VmSizeSelectCustom
                        vmData={{ ..._os, location }}
                        priceList={priceList ? locListToObjectPrices(priceList) : EMPTY_PRICES}
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
}
